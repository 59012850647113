// MatchupDashboard.js
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import './MatchupDashboard.css';
import MatchupHeader from '../matchup_dashboard_components/MatchupHeader';
import { teamsDict } from '../components/TeamInfo';
import { teamNameToAbbreviation } from '../components/TeamDictionary';
import SpreadBar from '../matchup_dashboard_components/matchup_stats_components/SpreadBar';
import MatchupInformation from './matchup_views/MatchupInformation';
import StartingLineup from '../matchup_dashboard_components/StartingLineup';
import TeamComparisonChart from '../matchup_dashboard_components/matchup_stats_components/TeamComparisonChart';
import TeamComparisonLineChart from '../matchup_dashboard_components/matchup_stats_components/TeamComparisonBarChart';
import MatchupPlayerRoster from '../matchup_dashboard_components/MatchupPlayerRoster';
import BoxScore from '../live_components/BoxScore';
import LivePlayerData from '../live_components/LivePlayerData';

const MatchupDashboard = () => {
  const location = useLocation();
  const { matchup } = location.state || {};

  const [activeView, setActiveView] = useState('matchupInfo');

  if (!matchup) {
    return <div>Error: No matchup data available.</div>;
  }

  const { awayTeamAbbr, homeTeamAbbr, time } = matchup;

  const reverseTeamAbbreviation = Object.keys(teamNameToAbbreviation).reduce((acc, key) => {
    acc[teamNameToAbbreviation[key]] = key;
    return acc;
  }, {});

  const awayTeamFullName = reverseTeamAbbreviation[awayTeamAbbr];
  const homeTeamFullName = reverseTeamAbbreviation[homeTeamAbbr];
  const homeTeamVenue = teamsDict[homeTeamFullName]?.venue;

  const homeTeamColors = teamsDict[homeTeamFullName]?.colors || ['#000000', '#FFFFFF'];
  const awayTeamColors = teamsDict[awayTeamFullName]?.colors || ['#000000', '#FFFFFF'];

  // Build the matchup string as expected by the backend (e.g. "Detroit Pistons vs Charlotte Hornets")
  const matchupString = `${homeTeamFullName} vs ${awayTeamFullName}`;

  return (
    <div className="layout-container">
      <div className="matchup-header">
        <MatchupHeader
          awayTeam={awayTeamAbbr}
          homeTeam={homeTeamAbbr}
          time={time}
          venue={`${homeTeamVenue}, ${teamsDict[homeTeamFullName]?.location}`}
        />
      </div>

      <div className="spread-bar-container">
        <SpreadBar homeTeam={homeTeamAbbr} awayTeam={awayTeamAbbr} spread={7.5} />
      </div>

      <div className="view-buttons-container">
        <div className="view-buttons">
          <button
            className={`view-button ${activeView === 'matchupInfo' ? 'active' : ''}`}
            style={{
              backgroundColor: activeView === 'matchupInfo' ? homeTeamColors[1] : homeTeamColors[0],
              color: '#FFFFFF',
            }}
            onClick={() => setActiveView('matchupInfo')}
          >
            Matchup Info
          </button>
          <button
            className={`view-button ${activeView === 'matchupStats' ? 'active' : ''}`}
            style={{
              backgroundColor: activeView === 'matchupStats' ? homeTeamColors[1] : homeTeamColors[0],
              color: '#FFFFFF',
            }}
            onClick={() => setActiveView('matchupStats')}
          >
            Matchup Stats
          </button>
          <button
            className={`view-button ${activeView === 'startingFive' ? 'active' : ''}`}
            style={{
              backgroundColor: activeView === 'startingFive' ? homeTeamColors[1] : homeTeamColors[0],
              color: '#FFFFFF',
            }}
            onClick={() => setActiveView('startingFive')}
          >
            Starting Five
          </button>
        </div>
        <div className="view-buttons right-buttons">
          {/* <button
            className={`view-button ${activeView === 'boxScore' ? 'active' : ''}`}
            style={{
              backgroundColor: activeView === 'boxScore' ? homeTeamColors[1] : homeTeamColors[0],
              color: '#FFFFFF',
            }}
            onClick={() => setActiveView('boxScore')}
          >
            Box Score
          </button>
          <button
            className={`view-button ${activeView === 'livePlayerData' ? 'active' : ''}`}
            style={{
              backgroundColor: activeView === 'livePlayerData' ? homeTeamColors[1] : homeTeamColors[0],
              color: '#FFFFFF',
            }}
            onClick={() => setActiveView('livePlayerData')}
          >
            Live Player Data
          </button> */}
        </div>
      </div>

      <div className="content">
        {activeView === 'matchupInfo' && (
          <MatchupInformation matchup={matchup} homeTeamAbbr={homeTeamAbbr} awayTeamAbbr={awayTeamAbbr} />
        )}
        {activeView === 'matchupStats' && (
          <div className="matchup-stats-content">
            <TeamComparisonChart homeTeamAbbr={homeTeamAbbr} awayTeamAbbr={awayTeamAbbr} />
            <TeamComparisonLineChart homeTeamAbbr={homeTeamAbbr} awayTeamAbbr={awayTeamAbbr} />
          </div>
        )}
        {activeView === 'startingFive' && (
          <div className="starting-five-content">
            <StartingLineup homeTeamAbbr={homeTeamAbbr} awayTeamAbbr={awayTeamAbbr} />
            <MatchupPlayerRoster homeTeamId={teamsDict[homeTeamFullName]?.team_id} awayTeamId={teamsDict[awayTeamFullName]?.team_id} />
          </div>
        )}
        {activeView === 'boxScore' && <BoxScore matchup={matchupString} />}
        {activeView === 'livePlayerData' && <LivePlayerData />}
      </div>
    </div>
  );
};

export default MatchupDashboard;
