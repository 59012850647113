import React, { useState } from 'react';
import { useKeycloak } from '../KeycloakProvider';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './CreateBlogPost.css';

// 1) Register additional fonts so Quill allows them in the dropdown.
const Quill = ReactQuill.Quill;
const Font = Quill.import('formats/font');

// Define which fonts you want to allow
Font.whitelist = [
  'sans-serif',
  'serif',
  'monospace',
  'montserrat',   // we'll define our custom font name
];
Quill.register(Font, true);

const CreateBlogPost = () => {
  const { keycloak, roles, token } = useKeycloak();

  // Check your Keycloak role (sea_news).
  const isPremiumUser = roles.includes('sea_news');

  // Local state for title & content
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  // Quill modules config for a custom toolbar
  const modules = {
    toolbar: [
      // Font family & size
      [{ font: [] }, { size: [] }],
      // Headings
      [{ header: [1, 2, 3, false] }],
      // Bold, italic, etc.
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      // Lists
      [{ list: 'ordered' }, { list: 'bullet' }],
      // Text color
      [{ color: [] }, { background: [] }],
      // Alignment
      [{ align: [] }],
      // Link & image
      ['link', 'image'],
      // Clear formatting
      ['clean'],
    ],
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!isPremiumUser) {
      alert('You are not authorized to create blog posts.');
      return;
    }

    try {
      const response = await fetch('/api/blog/create/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Optionally include token if your API requires it:
          // 'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          title: title,
          content: content,  // HTML from Quill
        }),
      });

      if (response.ok) {
        alert('Blog post created successfully!');
        setTitle('');
        setContent('');
      } else {
        const errorData = await response.json();
        console.error('Error creating blog post:', errorData);
        alert('Failed to create blog post.');
      }
    } catch (error) {
      console.error('Unexpected error:', error);
      alert('An error occurred while creating the blog post.');
    }
  };

  return (
    <div className="create-blog-container">
      <h2>Create a New Blog Post</h2>
      {isPremiumUser ? (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="title">Title: </label>
            <input
              id="title"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              className="title-input"
            />
          </div>

          <div className="form-group">
            <label>Content:</label>
            <ReactQuill
              theme="snow"
              value={content}
              onChange={(value) => setContent(value)}
              modules={modules}
              placeholder="Write something amazing..."
              className="quill-editor"
            />
          </div>

          <button type="submit" className="submit-button">
            Create
          </button>
        </form>
      ) : (
        <p>You do not have permission to create blog posts.</p>
      )}
    </div>
  );
};

export default CreateBlogPost;
