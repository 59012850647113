import React from "react";
import "./Footer.css";
import discordIcon from "./assets/discord.png"; // Adjust the path if necessary

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <img src="/big_logo.png" alt="Showstone Logo" className="footer-logo" />
        <p className="footer-description">
          This website's content is provided solely for entertainment purposes. Please be mindful of and adhere to the
          sports betting laws applicable in your jurisdiction.
        </p>
        <p className="footer-disclaimer">
          This website operates independently and is not connected to, endorsed by, or affiliated with the NFL, MLB,
          NBA, NHL, PGA, UFC, WNBA, or any other sports leagues or teams. Any names, logos, or images featured are
          trademarks™ or registered® trademarks owned by their respective organizations, used here without implying any
          association or endorsement.
        </p>
        <p className="footer-helpline">
          If you or someone you know is struggling with gambling, help is available. Contact the{" "}
          <span className="helpline-highlight">National Problem Gambling Helpline at 1-800-522-4700</span> for
          confidential support, available 24/7. Talking to a professional can provide guidance, resources, and support
          tailored to your needs. Remember, help is just a call away.
        </p>
        <div className="footer-links">
          <a href="/PrivacyPolicy" className="footer-link">
            Privacy Policy
          </a>
          <a href="/CookiePolicy" className="footer-link">
            Cookie Policy
          </a>
          <a href="/TermsOfService" className="footer-link">
            Terms of Service
          </a>
        </div>
        <div className="footer-contact">
          <p className="contact-email">
            Contact us:{" "}
            <a href="mailto:showstone@showstone.io" className="email-link">
              showstone@showstone.io
            </a>
          </p>
        </div>
        {/* Discord Link */}
        <div className="footer-discord">
          <a
            href="https://discord.gg/jtBMpcgZ"
            target="_blank"
            rel="noopener noreferrer"
            className="discord-link"
          >
            <img src={discordIcon} alt="Join our Discord" className="discord-icon" />
            <span>Discord</span>
          </a>
        </div>
        {/* 
        The social icons block is commented out below.
        <div className="footer-socials">
          ...
        </div> 
        */}
      </div> 
    </footer>
  );
};

export default Footer;
