import React, { useState, useEffect, useRef } from "react";
import TopPickCard from "./TopPickCard";
import { fetchData } from "../api";
import { useKeycloak } from "../KeycloakProvider";
import { usePlayerColors } from "../main_dashboard_components/PlayerColorContext";
import "./TopPickCards.css";

// Utility functions
function passesX15Range(overCount, xValue) {
  if (!xValue) return true;
  const xNum = parseInt(xValue, 10);
  if (isNaN(xNum)) return true;
  const lowerBound = 15 - xNum;
  return overCount >= xNum || overCount <= lowerBound;
}

function symmetricalRank(overCount) {
  return Math.min(overCount, 15 - overCount);
}

function computeLogValue(log, feature) {
  const f = (feature || "").toLowerCase();
  let val = 0;
  if (f.includes("pts")) val += log.pts;
  if (f.includes("reb")) val += log.reb;
  if (f.includes("ast")) val += log.ast;
  return val;
}

function sumRollingMean(pick) {
  const f = (pick.feature || "").toLowerCase();
  let val = 0;
  if (f.includes("pts")) val += (pick.pts_vs_opponent_rolling_2_mean || 0);
  if (f.includes("reb")) val += (pick.reb_vs_opponent_rolling_2_mean || 0);
  if (f.includes("ast")) val += (pick.ast_vs_opponent_rolling_2_mean || 0);
  return parseFloat(val.toFixed(2));
}

function sumHomeAwayRolling15(pick) {
  const f = (pick.feature || "").toLowerCase();
  let val = 0;
  if (f.includes("pts")) val += (pick.home_or_away_rolling_pts_15_games || 0);
  if (f.includes("reb")) val += (pick.home_or_away_rolling_reb_15_games || 0);
  if (f.includes("ast")) val += (pick.home_or_away_rolling_ast_15_games || 0);
  return parseFloat(val.toFixed(2));
}

export default function TopPickCards() {
  // ─── Always call hooks at the top ───
  const { token, roles, keycloakId } = useKeycloak();
  const isPremiumUser = roles.includes("premium");
  const { cart, setCart } = usePlayerColors();

  const [displayGoldFilter, setDisplayGoldFilter] = useState(false);
  const [pickGoldFilter, setPickGoldFilter] = useState(false);
  const [pickTypeFilter, setPickTypeFilter] = useState(["all"]);
  const [pickTypeDropdownOpen, setPickTypeDropdownOpen] = useState(false);
  const [topPicks, setTopPicks] = useState([]);
  const [last15Logs, setLast15Logs] = useState([]);
  const [featureFilter, setFeatureFilter] = useState([]);
  const [featureDropdownOpen, setFeatureDropdownOpen] = useState(false);
  const [x15Filter, setX15Filter] = useState("");
  const [overUnderFilter, setOverUnderFilter] = useState("All");
  const [oddsMin, setOddsMin] = useState("");
  const [oddsMax, setOddsMax] = useState("");
  const [probabilityFilter, setProbabilityFilter] = useState(60);
  const multiSelectRef = useRef(null);

  // ─── Data Loading ───
  async function loadData() {
    try {
      const picksUrl = `top-picks/?auth0_id=${keycloakId}`;
      const logsUrl = `top-picks-last15/?auth0_id=${keycloakId}`;
      const [picksData, logsData] = await Promise.all([
        fetchData(picksUrl, token),
        fetchData(logsUrl, token),
      ]);
      setTopPicks(picksData || []);
      setLast15Logs(logsData || []);
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  }

  useEffect(() => {
    if (!token) return;
    loadData();
  }, [token, isPremiumUser]);

  // ─── Merge Data ───
  const merged = topPicks.map((pick) => {
    const logs = last15Logs
      .filter((lg) => lg.player_id === pick.player_id)
      .sort((a, b) => new Date(a.game_date) - new Date(b.game_date));

    let overCount = 0;
    const barValues = logs.map((lg) => {
      const val = computeLogValue(lg, pick.feature);
      if (val > (pick.threshold || 0)) overCount++;
      return {
        date: new Date(lg.game_date),
        value: val,
        pts: lg.pts,
        reb: lg.reb,
        ast: lg.ast,
        min: lg.min || 0,
      };
    });

    const minutesArr = barValues.map((bv) => bv.min);
    const l15MinAvg = minutesArr.length
      ? minutesArr.reduce((acc, m) => acc + m, 0) / minutesArr.length
      : 0;
    const last5Min = minutesArr.slice(-5);
    const l5MinAvg = last5Min.length
      ? last5Min.reduce((acc, m) => acc + m, 0) / last5Min.length
      : 0;
    const minutesDiff = parseFloat((l5MinAvg - l15MinAvg).toFixed(2));
    const gamesWithout = pick.total_games_without || 0;

    const isGold = !!pick.is_gold_pick;
    const isFire = pick.number_of_times_picked >= 5;

    return {
      ...pick,
      logs: barValues,
      over_count: overCount,
      minutes_diff: minutesDiff,
      rolling_mean_sum: sumRollingMean(pick),
      rolling_home_sum: sumHomeAwayRolling15(pick),
      total_games_without: gamesWithout,
      isGold,
      isFire,
    };
  });

  // ─── Apply Filters and Sorting ───
  const filtered = merged.filter((p) => {
    // Feature filter
    if (featureFilter.length > 0 && !featureFilter.includes("all")) {
      const matched = featureFilter.some(
        (f) => f.toLowerCase() === p.feature.toLowerCase()
      );
      if (!matched) return false;
    }
    // X/15 filter.
    if (!passesX15Range(p.over_count || 0, x15Filter)) return false;
    // Confidence filter.
    if ((p.feature_probability || 0) * 100 < probabilityFilter) return false;
    // Over/Under filter.
    if (overUnderFilter !== "All") {
      const isOverVal = (p.feature_prediction || 0) > (p.threshold || 0);
      if (overUnderFilter === "over" && !isOverVal) return false;
      if (overUnderFilter === "under" && isOverVal) return false;
    }
    // Odds filters.
    if (oddsMin && p.odds < parseFloat(oddsMin)) return false;
    if (oddsMax && p.odds > parseFloat(oddsMax)) return false;
    // Show Gold filter.
    if (displayGoldFilter && !p.is_gold_pick) return false;
    // Pick Type filter.
    if (pickTypeFilter.length > 0 && !pickTypeFilter.includes("all")) {
      if (
        !(
          (pickTypeFilter.includes("showstone") && p.is_gold_pick) ||
          (pickTypeFilter.includes("hot") && p.isFire)
        )
      ) {
        return false;
      }
    }
    return true;
  });

  const sorted = [...filtered].sort(
    (a, b) => symmetricalRank(a.over_count || 0) - symmetricalRank(b.over_count || 0)
  );

  // ─── Multi-select Logic for Features and Pick Types ───
  const toggleFeatureDropdown = () => setFeatureDropdownOpen(!featureDropdownOpen);
  const handleFeatureSelection = (val) => {
    if (val === "all") {
      if (featureFilter.includes("all")) {
        setFeatureFilter([]);
      } else {
        setFeatureFilter(["all"]);
      }
      return;
    }
    if (featureFilter.includes("all")) setFeatureFilter([]);
    if (featureFilter.includes(val)) {
      setFeatureFilter(featureFilter.filter((f) => f !== val));
    } else {
      setFeatureFilter([...featureFilter, val]);
    }
  };

  const togglePickTypeDropdown = () => setPickTypeDropdownOpen(!pickTypeDropdownOpen);
  const handlePickTypeSelection = (val) => {
    if (val === "all") {
      if (pickTypeFilter.includes("all")) {
        setPickTypeFilter([]);
      } else {
        setPickTypeFilter(["all"]);
      }
      return;
    }
    if (pickTypeFilter.includes("all")) setPickTypeFilter([]);
    if (pickTypeFilter.includes(val)) {
      setPickTypeFilter(pickTypeFilter.filter((f) => f !== val));
    } else {
      setPickTypeFilter([...pickTypeFilter, val]);
    }
  };

  // ─── Other Helper Functions ───
  const handleDownloadCSV = () => {
    const headers = [
      "PlayerName",
      "Feature",
      "Threshold",
      "Projected",
      "Probability",
      "OverCount",
      "MinutesDiff",
    ];
    const rows = [];
    rows.push(headers);
    sorted.forEach((p) => {
      rows.push([
        p.player_name,
        p.feature,
        p.threshold,
        p.feature_prediction,
        p.feature_probability,
        p.over_count,
        p.minutes_diff,
      ]);
    });
    const csvContent =
      "data:text/csv;charset=utf-8," + rows.map((r) => r.join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "filtered_picks.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handlePlusClick = async (p) => {
    try {
      if (roles.includes("sea_news") && pickGoldFilter === true) {
        await fetchData(`top-picks/${p.id}/increment_pick/`, token, "POST", {
          is_gold_pick: true,
        });
      } else {
        await fetchData(`top-picks/${p.id}/increment_pick/`, token, "POST", {});
      }
      loadData();
      addToCache(p);
    } catch (err) {
      console.error("Error updating top pick:", err);
      alert("Error updating top pick");
    }
  };

  const addToCache = (p) => {
    const over_under =
      (p.feature_prediction || 0) > (p.threshold || 0) ? "over" : "under";
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0];
    const newEntry = {
      player_id: p.player_id,
      player_name: p.player_name || "Unknown",
      feature: p.feature,
      threshold: p.threshold,
      over_under: over_under,
      game_date: formattedDate,
      game_time: p.time || "",
      comment: "",
      feature_prediction: p.feature_prediction || 0,
      feature_probability: p.feature_probability || 0,
      next_opponent: p.next_opponent || "TBD",
    };

    const isDuplicate = cart.some(
      (item) =>
        item.player_id === newEntry.player_id &&
        item.feature === newEntry.feature &&
        item.threshold === newEntry.threshold
    );
    if (isDuplicate) {
      alert(
        `Player ${newEntry.player_name} with feature ${newEntry.feature} is already added.`
      );
      return;
    }

    const updatedCart = [...cart, newEntry];
    setCart(updatedCart);
    localStorage.setItem("savedPlayers", JSON.stringify(updatedCart));
    alert("Player successfully added!");
  };

  // ─── Conditional Rendering in the Return Statement ───
  return !isPremiumUser ? (
    <div
      style={{
        textAlign: "center",
        margin: "2rem",
        fontFamily: "var(--font-main)",
      }}
    >
      <p style={{ fontSize: "1.2rem", marginBottom: "1rem" }}>
        This page is only for premium members.
      </p>
      <button
        onClick={() => (window.location.href = "/Subscription")}
        style={{
          padding: "0.75rem 1.5rem",
          backgroundColor: "var(--main-color)",
          border: "none",
          borderRadius: "4px",
          color: "#fff",
          cursor: "pointer",
          fontSize: "1rem",
        }}
      >
        Buy Premium
      </button>
    </div>
  ) : (
    <div
      style={{
        maxWidth: "1440px",
        margin: "0 auto",
        padding: "20px",
        fontFamily: "var(--font-main)",
      }}
    >
      {/* Filters Row */}
      <div
        className="top-cards-filters"
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          gap: "1rem",
        }}
      >
        {/* Feature Filter */}
        <div
          className="top-cards-filter-item top-cards-multi-select-wrapper"
          ref={multiSelectRef}
        >
          <label style={{ color: "var(--main-color)" }}>Features:</label>
          <div
            className="top-cards-multi-select-control"
            onClick={toggleFeatureDropdown}
            style={{ color: "var(--main-color)" }}
          >
            <span>
              {featureFilter.includes("all")
                ? "All Features"
                : featureFilter.length > 0
                ? featureFilter.join(", ").toUpperCase()
                : "All Features"}
            </span>
            <i className="top-cards-arrow"></i>
          </div>
          {featureDropdownOpen && (
            <div className="top-cards-multi-select-options">
              <div className="top-cards-option">
                <label>
                  <input
                    type="checkbox"
                    checked={featureFilter.includes("all")}
                    onChange={() => handleFeatureSelection("all")}
                  />
                  ALL
                </label>
              </div>
              {["pts", "reb", "ast", "pts_ast", "pts_reb", "ast_reb", "pts_ast_reb"].map(
                (feat) => (
                  <div key={feat} className="top-cards-option">
                    <label>
                      <input
                        type="checkbox"
                        checked={featureFilter.includes(feat)}
                        onChange={() => handleFeatureSelection(feat)}
                      />
                      {feat.toUpperCase()}
                    </label>
                  </div>
                )
              )}
            </div>
          )}
        </div>

        {/* X/15 Filter */}
        <div className="top-cards-filter-item">
          <label style={{ color: "var(--main-color)" }}>X/15:</label>
          <input
            type="number"
            placeholder="12 =>"
            value={x15Filter}
            onChange={(e) => setX15Filter(e.target.value)}
            style={{ width: "90px" }}
          />
        </div>

        {/* Confidence Filter */}
        <div className="top-cards-filter-item">
          <label style={{ color: "var(--main-color)" }}>Confidence</label>
          <input
            type="number"
            value={probabilityFilter}
            onChange={(e) => setProbabilityFilter(parseFloat(e.target.value))}
            min="0"
            max="100"
            style={{ width: "90px" }}
          />
        </div>

        {/* Over/Under Filter */}
        <div className="top-cards-filter-item">
          <label style={{ color: "var(--main-color)" }}>Over/Under:</label>
          <select
            value={overUnderFilter}
            onChange={(e) => setOverUnderFilter(e.target.value)}
            style={{ color: "var(--main-color)" }}
          >
            <option value="All">All</option>
            <option value="over">Over</option>
            <option value="under">Under</option>
          </select>
        </div>

        {/* Pick Type Filter */}
        <div className="top-cards-filter-item top-cards-multi-select-wrapper">
          <label style={{ color: "var(--main-color)" }}>Pick Type:</label>
          <div
            className="top-cards-multi-select-control"
            onClick={togglePickTypeDropdown}
            style={{ color: "var(--main-color)" }}
          >
            <span>
              {pickTypeFilter.includes("all")
                ? "All Picks"
                : pickTypeFilter.length > 0
                ? pickTypeFilter
                    .map((t) =>
                      t === "showstone"
                        ? "Showstone Pick"
                        : t === "hot"
                        ? "Hot Picks"
                        : ""
                    )
                    .join(", ")
                : "All Picks"}
            </span>
            <i className="top-cards-arrow"></i>
          </div>
          {pickTypeDropdownOpen && (
            <div className="top-cards-multi-select-options">
              <div className="top-cards-option">
                <label>
                  <input
                    type="checkbox"
                    checked={pickTypeFilter.includes("all")}
                    onChange={() => handlePickTypeSelection("all")}
                  />
                  All Picks
                </label>
              </div>
              <div className="top-cards-option">
                <label>
                  <input
                    type="checkbox"
                    checked={pickTypeFilter.includes("showstone")}
                    onChange={() => handlePickTypeSelection("showstone")}
                  />
                  Showstone Pick
                </label>
              </div>
              <div className="top-cards-option">
                <label>
                  <input
                    type="checkbox"
                    checked={pickTypeFilter.includes("hot")}
                    onChange={() => handlePickTypeSelection("hot")}
                  />
                  Hot Picks
                </label>
              </div>
            </div>
          )}
        </div>

        {roles.includes("sea_news") && (
          <div className="top-cards-filter-item">
            <label style={{ color: "var(--main-color)" }}>Pick Gold:</label>
            <select
              value={pickGoldFilter ? "yes" : "no"}
              onChange={(e) => setPickGoldFilter(e.target.value === "yes")}
              style={{ color: "var(--main-color)" }}
            >
              <option value="no">Increment</option>
              <option value="yes">Set Gold</option>
            </select>
          </div>
        )}

        <div className="top-cards-filter-item">
          <button
            onClick={handleDownloadCSV}
            style={{
              backgroundColor: "var(--main-color)",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
              padding: "6px 12px",
              marginTop: "12px",
              cursor: "pointer",
            }}
          >
            Download CSV
          </button>
        </div>
      </div>

      {/* Cards Container */}
      <div className="top-pick-cards-container">
        {sorted.length > 0 ? (
          sorted.map((p, idx) => {
            const valuesArray = (p.logs || []).map((obj) => obj.value);
            const maxValue = Math.max(...valuesArray, p.threshold || 0, 1);
            const CHART_HEIGHT = 120;
            const scaleFactor = CHART_HEIGHT / maxValue;
            const l15 =
              valuesArray.length > 0
                ? (
                    valuesArray.reduce((acc, v) => acc + v, 0) /
                    valuesArray.length
                  ).toFixed(1)
                : "0.0";
            const last5 = valuesArray.slice(-5);
            const l5 =
              last5.length > 0
                ? (last5.reduce((acc, v) => acc + v, 0) / last5.length).toFixed(1)
                : "0.0";
            let percentageChange = "0.0";
            if (parseFloat(l15) !== 0) {
              percentageChange = (
                ((parseFloat(l5) - parseFloat(l15)) / parseFloat(l15)) *
                100
              ).toFixed(1);
            }
            return (
              <div key={idx} style={{ position: "relative" }}>
                <button
                  className="add-to-cache-button"
                  onClick={() => handlePlusClick(p)}
                >
                  +
                </button>
                <TopPickCard
                  playerName={p.player_name || "Unknown"}
                  feature={p.feature}
                  lineValue={p.threshold || 0}
                  predicted={p.feature_prediction || 0}
                  probability={
                    p.feature_probability
                      ? (p.feature_probability * 100).toFixed(1) + "%"
                      : "0%"
                  }
                  l15={l15}
                  l5Average={parseFloat(l5)}
                  percentageChange={percentageChange}
                  overThreshold={p.over_count || 0}
                  totalBars={valuesArray.length}
                  barValues={p.logs || []}
                  impact={p.impact || 0}
                  nextOpponent={p.next_opponent || "TBD"}
                  rollingValue2Mean={p.rolling_mean_sum || 0}
                  rollingHome15={p.rolling_home_sum || 0}
                  minutesDiff={p.minutes_diff || 0}
                  injuredPlayersDetails={p.injured_players_details || ""}
                  isGold={p.isGold}
                  isFire={p.isFire}
                />
              </div>
            );
          })
        ) : (
          <p>No picks match filters.</p>
        )}
      </div>
    </div>
  );
}
