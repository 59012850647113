// TicketAnalyzer.js
import React, { useState, useEffect } from 'react';
import AvailablePlayers from '../ticket_analyzer_components/AvailablePlayers';
import SelectedPlayers from '../ticket_analyzer_components/SelectedPlayers';
import ParlayBuilder from '../ticket_analyzer_components/ParlayBuilder';
import { useKeycloak } from '../KeycloakProvider';
import { v4 as uuidv4 } from 'uuid';
import './TicketAnalyzer.css';
import { matchups } from '../components/MatchupInfo';
import { teamsDict } from '../components/TeamInfo';

const TicketAnalyzer = () => {
  // Always call hooks unconditionally
  const { token, keycloakId, roles } = useKeycloak();
  const isPremiumUser = roles.includes("premium");

  const [playerIds, setPlayerIds] = useState(() => {
    const cachedSelectedPlayers = JSON.parse(localStorage.getItem('savedPlayers')) || [];
    return cachedSelectedPlayers.map(player => player.playerId);
  });
  const [parlayPlayers, setParlayPlayers] = useState(() => {
    const cachedParlayPlayers = JSON.parse(localStorage.getItem('parlayPlayers')) || [];
    return cachedParlayPlayers;
  });
  const [loading, setLoading] = useState(false);
  const [parsedMatchups, setParsedMatchups] = useState([]);
  const [selectedMatchup, setSelectedMatchup] = useState(null);

  useEffect(() => {
    const parsed = matchups.map((matchup) => {
      const [awayTeam, homeTeam] = matchup.matchup.split(' @ ');
      return { ...matchup, awayTeam, homeTeam };
    });
    setParsedMatchups(parsed);
  }, []);

  const loadSelectedPlayers = () => {
    const cachedPlayers = localStorage.getItem('savedPlayers');
    if (cachedPlayers) {
      const parsedPlayers = JSON.parse(cachedPlayers);
      setPlayerIds(parsedPlayers.map((player) => player.playerId));
      setSelectedMatchup(null); // Clear selected matchup
    }
  };

  const handleMatchupSelect = (matchup) => {
    setLoading(true);
    try {
      const homeTeamFullName = matchup.homeTeam;
      const awayTeamFullName = matchup.awayTeam;
      // Pass the full team names to SelectedPlayers
      setSelectedMatchup({
        homeTeam: homeTeamFullName,
        awayTeam: awayTeamFullName,
      });
      setPlayerIds([]); // Clear playerIds since we'll use matchup data in SelectedPlayers
    } catch (error) {
      console.error('Failed to handle matchup click:', error);
    } finally {
      setLoading(false);
    }
  };

  const addToParlayBuilder = (player) => {
    const playerExists = parlayPlayers.some(p => p.playerId === player.playerId);
    if (!playerExists) {
      const updatedParlayPlayers = [...parlayPlayers, player];
      setParlayPlayers(updatedParlayPlayers);
      localStorage.setItem('parlayPlayers', JSON.stringify(updatedParlayPlayers));
    } else {
      // Optionally handle the case where the player is already in the parlay
    }
  };

  const removeFromSelectedPlayers = (playerId) => {
    const updatedPlayerIds = playerIds.filter(id => id !== playerId);
    setPlayerIds(updatedPlayerIds);

    const updatedPlayers = JSON.parse(localStorage.getItem('savedPlayers')).filter(p => p.playerId !== playerId);
    localStorage.setItem('savedPlayers', JSON.stringify(updatedPlayers));
  };

  const clearParlay = () => {
    setParlayPlayers([]);
    localStorage.removeItem('parlayPlayers');
  };

  const [ticketId] = useState(() => uuidv4()); // Generate ticketId once on mount

  // --- Premium Check ---
  // Now that all hooks have been called, if the user is not premium, return a message.
  if (!token || !isPremiumUser) {
    return (
      <div style={{ textAlign: 'center', margin: '2rem', fontFamily: 'var(--font-main)' }}>
        <p style={{ fontSize: '1.2rem', marginBottom: '1rem' }}>
          This page is only for premium members.
        </p>
        <button
          onClick={() => window.location.href = "/Subscription"}
          style={{
            padding: '0.75rem 1.5rem',
            backgroundColor: 'var(--main-color)',
            fontFamily: 'var(--font-main)',
            border: 'none',
            borderRadius: '4px',
            color: '#fff',
            cursor: 'pointer',
            fontSize: '1rem',
          }}
        >
          Buy Premium
        </button>
      </div>
    );
  }

  // --- Render Ticket Analyzer for Premium Users ---
  return (
    <div className="ticket-analyzer-wrapper">
      <div className="ticket-analyzer-layout">
        <AvailablePlayers
          loadSelectedPlayers={loadSelectedPlayers}
          parsedMatchups={parsedMatchups}
          onMatchupSelect={handleMatchupSelect}
          loading={loading}
        />

        <div className="main-content">
          <SelectedPlayers 
            playerIds={playerIds} 
            selectedMatchup={selectedMatchup}
            addToParlayBuilder={addToParlayBuilder}
            removeFromSelectedPlayers={removeFromSelectedPlayers}
          />
          <ParlayBuilder 
            parlayPlayers={parlayPlayers} 
            removeFromParlay={(name) => {
              const updatedParlayPlayers = parlayPlayers.filter(p => p.name !== name);
              setParlayPlayers(updatedParlayPlayers);
              localStorage.setItem('parlayPlayers', JSON.stringify(updatedParlayPlayers));
            }}
            clearParlay={clearParlay}
            keycloakId={keycloakId}
            ticketId={ticketId}
            token={token}
          />
        </div>
      </div>
    </div>
  );
};

export default TicketAnalyzer;
