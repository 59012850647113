import React, { useState, useEffect } from 'react';
import { usePlayerColors } from './PlayerColorContext';
import { players } from '../components/PlayerInfo';
import './PlayerSelection.css';
import { teamNameToAbbreviation } from '../components/TeamDictionary';

const PlayerSelection = ({ selectedFeature }) => {
  const {
    teamColors,
    selectedPlayer,
    cart,
    setCart,
    thresholds,
    opponentName,
    adjustedThresholds,
    nextGameDetails, // Access nextGameDetails for date and time
  } = usePlayerColors();

  const [lineType, setLineType] = useState('over');
  const [comment, setComment] = useState('');
  const [isMinimized, setIsMinimized] = useState(true);
  console.log('cart',cart);
  // Reset comment when the selected player changes
  useEffect(() => {
    setComment('');
  }, [selectedPlayer]);

  // Get the current player's name from global players
  const playerName = players[selectedPlayer]?.name || 'Unknown Player';

  // Get the threshold value for the selected feature from context
  const thresholdValue = thresholds[playerName]?.[selectedFeature];
  const adjustedThresholdValue = adjustedThresholds[playerName]?.[selectedFeature];

  // Use the adjusted threshold if available, otherwise use the normal threshold
  const line =
    adjustedThresholdValue !== undefined && adjustedThresholdValue !== null
      ? adjustedThresholdValue
      : thresholdValue !== undefined && thresholdValue !== null
      ? thresholdValue
      : 6.5; // default value

  const formattedLine = Number(line).toFixed(1);

  // Mapping of feature names for display (this mapping is only used for display purposes)
  const featureMap = {
    Total: 'PTS',
    AST: 'AST',
    REB: 'REB',
    BLK: 'BLK',
    STL: 'STL',
    'Pts+Ast': 'Pts+Ast',
    'Pts+Reb': 'Pts+Reb',
    'Pts+Ast+Reb': 'Pts+Ast+Reb',
    'Ast+Reb': 'Ast+Reb',
  };

  // When adding a player, we build a new entry using the new cache keys.
  // We use the current lineType as the over_under value.
  const addToCart = () => {
    if (selectedPlayer) {
      const mappedFeature = featureMap[selectedFeature] || selectedFeature;

      // Check for duplicate in the current cart using the new key names.
      const isPlayerInCart = cart.some(
        (item) =>
          item.player_id === selectedPlayer && item.feature === mappedFeature
      );

      if (isPlayerInCart) {
        alert(`${playerName} with feature ${mappedFeature} is already added to the cart.`);
        return;
      }
      console.log('opponentName',opponentName)
      const playerDetails = players[selectedPlayer];
      // Use nextGameDetails if provided, otherwise use the current date/time.
      const currentDate = nextGameDetails?.date || new Date().toISOString().split("T")[0];
      const currentTime = nextGameDetails?.time || "";
      console.log(selectedPlayer);
      // Build the new entry with the same field names as used by TopPickCards.
      const newEntry = {
        player_id: selectedPlayer,
        player_name: playerDetails.name,
        feature: mappedFeature,
        threshold: Number(formattedLine),
        over_under: lineType, // You could also compute this from a prediction if available.
        game_date: currentDate,
        game_time: currentTime,
        comment: "",
        feature_prediction: thresholds[playerDetails.name]?.[selectedFeature] || 0,
        feature_probability: 0, // Default to 0 if not available
        next_opponent: teamNameToAbbreviation[opponentName],
      };

   
      const updatedCart = [...cart, newEntry];
      setCart(updatedCart);
      localStorage.setItem("savedPlayers", JSON.stringify(updatedCart));
      setComment('');
    }
  };

  // Remove an entry by index from the cart and update the cache
  const removeFromCart = (index) => {
    const updatedCart = cart.filter((_, i) => i !== index);
    setCart(updatedCart);
    localStorage.setItem("savedPlayers", JSON.stringify(updatedCart));
  };

  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  // Background color and font color calculations remain the same.
  const backgroundColor = teamColors[0] || '#ffffff';

  function getLuminance(color) {
    const hex = color.replace('#', '');
    const r = parseInt(hex.substr(0, 2), 16) / 255;
    const g = parseInt(hex.substr(2, 2), 16) / 255;
    const b = parseInt(hex.substr(4, 2), 16) / 255;
    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
  }

  function getFontColor(color) {
    const luminance = getLuminance(color);
    return luminance > 0.5 ? '#000000' : '#FFFFFF';
  }

  const fontColor = getFontColor(backgroundColor);

  return (
    <div
      className={`player-selection ${isMinimized ? 'minimized' : ''}`}
      style={{ backgroundColor, color: fontColor }}
    >
      <div className="player-selection-toggle-button" onClick={toggleMinimize}>
        {isMinimized ? '+' : '-'}
      </div>
      {!isMinimized && (
        <>
          <h2 className="player-selection-title">Player Selection</h2>
          {selectedPlayer && (
            <>
              <button
                onClick={addToCart}
                style={{
                  backgroundColor: teamColors[1] || '#555',
                  color: getFontColor(teamColors[1] || '#555'),
                }}
              >
                Add {players[selectedPlayer].name} {featureMap[selectedFeature]} {formattedLine} {lineType}
              </button>
              <select onChange={(e) => setLineType(e.target.value)} value={lineType}>
                <option value="over">Over</option>
                <option value="under">Under</option>
              </select>
              <input
                type="text"
                placeholder="Add a comment..."
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </>
          )}
          <h3>Selected Players:</h3>
          {cart.length > 0 ? (
            cart.map((item, index) => (
              <div
                key={index}
                className="player-selection-cart-item"
                style={{
                  backgroundColor: teamColors[2] || '#ffffff',
                  color: getFontColor(teamColors[2] || '#ffffff'),
                }}
              >
                <span>
                  {item.player_name} - {item.feature.toUpperCase()} {item.threshold} {item.over_under}
                </span>
                <button
                  className="remove-button"
                  onClick={() => removeFromCart(index)}
                  style={{
                    backgroundColor: teamColors[1] || '#555',
                    color: getFontColor(teamColors[1] || '#555'),
                  }}
                >
                  Remove
                </button>
              </div>
            ))
          ) : (
            <p>No players selected yet.</p>
          )}
        </>
      )}
    </div>
  );
};

export default PlayerSelection;
