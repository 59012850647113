import React, { useState, useEffect } from "react";
import "./BoxScore.css";

// Define the priority order for stat columns (keys are as returned by the API).
// Adjust keys as needed to match the API values (for example, use "points" for PTS, "assists" for AST, etc.)
const COLUMN_PRIORITY = {
  points: 1,
  assists: 2,
  blocks: 3,
  minutes: 4, // team minutes will be a string; for players, "minutes" may be different.
  turnovers: 5,
  twoPointersPercentage: 6,
  threePointersPercentage: 7,
};

const BoxScore = ({ matchup: defaultMatchup = "" }) => {
  const [selectedMatchup, setSelectedMatchup] = useState(defaultMatchup);
  const [availableMatchups, setAvailableMatchups] = useState([]);
  const [boxScoreData, setBoxScoreData] = useState(null);

  useEffect(() => {
    if (defaultMatchup) {
      setSelectedMatchup(defaultMatchup);
    }
  }, [defaultMatchup]);

  useEffect(() => {
    const fetchMatchups = async () => {
      try {
        const res = await fetch("/api/live-data/");
        const data = await res.json();
        const matchups = data.games.map((game) => game.matchup);
        const uniqueMatchups = Array.from(new Set(matchups));
        setAvailableMatchups(uniqueMatchups);
      } catch (error) {
        console.error("Error fetching matchups:", error);
      }
    };
    fetchMatchups();
  }, []);

  useEffect(() => {
    if (!selectedMatchup) return;
    const fetchBoxScore = async () => {
      try {
        const res = await fetch(
          `/api/box-score/?matchup=${encodeURIComponent(selectedMatchup)}`
        );
        const data = await res.json();
        setBoxScoreData(data);
      } catch (error) {
        console.error("Error fetching box score:", error);
      }
    };
    fetchBoxScore();
  }, [selectedMatchup]);

  // Build the union of statistic keys from team totals and players.
  const getStatKeys = (team) => {
    const keysSet = new Set();
    if (team.totals) {
      Object.keys(team.totals).forEach((key) => {
        // Exclude "minutes" if it is a string? (but we want to display it as a summary)
        keysSet.add(key);
      });
    }
    team.players.forEach((player) => {
      if (player.statistics) {
        Object.keys(player.statistics).forEach((key) => keysSet.add(key));
      }
    });
    const keys = Array.from(keysSet);
    keys.sort((a, b) => {
      const aPriority = COLUMN_PRIORITY[a] !== undefined ? COLUMN_PRIORITY[a] : 100;
      const bPriority = COLUMN_PRIORITY[b] !== undefined ? COLUMN_PRIORITY[b] : 100;
      if (aPriority !== bPriority) return aPriority - bPriority;
      return a.localeCompare(b);
    });
    return keys;
  };

  // Render a dynamic table for one team.
  const renderTeamTable = (team) => {
    const statKeys = getStatKeys(team);
    return (
      <div className="team-boxscore">
        {/* Team summary header */}
        <div className="team-summary">
          <span className="team-pts">{team.totals.points} PTS</span>
          <span className="team-minutes">{team.totals.minutes}</span>
        </div>
        <div className="players-table-container">
          <table className="players-table">
            <thead>
              <tr>
                <th>Player</th>
                {statKeys.map((key) => (
                  <th key={key}>{key.toUpperCase()}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {team.players.map((player) => (
                <tr key={player.name}>
                  <td>{player.name}</td>
                  {statKeys.map((key) => (
                    <td key={key}>
                      {player.statistics && player.statistics[key] !== undefined
                        ? player.statistics[key]
                        : 0}
                    </td>
                  ))}
                </tr>
              ))}
              <tr className="totals-row">
                <td>Total</td>
                {statKeys.map((key) => (
                  <td key={key}>
                    {team.totals && team.totals[key] !== undefined ? team.totals[key] : 0}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div className="boxscore-container">
      <h1>Box Score</h1>
      {!defaultMatchup && (
        <div className="boxscore-filters">
          <select
            value={selectedMatchup}
            onChange={(e) => setSelectedMatchup(e.target.value)}
            className="boxscore-select"
          >
            <option value="">Select Matchup</option>
            {availableMatchups.map((m) => (
              <option key={m} value={m}>
                {m}
              </option>
            ))}
          </select>
        </div>
      )}
      {boxScoreData ? (
        <div className="boxscore-content">
          <h2>{boxScoreData.matchup || "N/A"}</h2>
          <div className="game-info">
            <p>Status: {boxScoreData.gameInfo?.status || "N/A"}</p>
            <p>Date/Time: {boxScoreData.gameInfo?.date_time_local || "N/A"}</p>
            <p>Arena: {boxScoreData.gameInfo?.arenaName || "N/A"}</p>
          </div>
          {boxScoreData.quarters && boxScoreData.quarters.length > 0 && (
            <div className="quarters-table-container">
              <h3>Per Quarter Scores</h3>
              <table className="quarters-table">
                <thead>
                  <tr>
                    <th>Quarter</th>
                    <th>Home</th>
                    <th>Away</th>
                  </tr>
                </thead>
                <tbody>
                  {boxScoreData.quarters.map((q, index) => (
                    <tr key={index}>
                      <td>{q.quarter}</td>
                      <td>{q.home}</td>
                      <td>{q.away}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          <div className="teams-boxscore">
            {Object.keys(boxScoreData.teams).map((teamName) => {
              const team = {
                name: teamName,
                totals: boxScoreData.teams[teamName].totals,
                players: boxScoreData.teams[teamName].players,
              };
              return <div key={teamName}>{renderTeamTable(team)}</div>;
            })}
          </div>
        </div>
      ) : (
        selectedMatchup && <p>Loading box score...</p>
      )}
    </div>
  );
};

export default BoxScore;
