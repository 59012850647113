import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchData } from "../api";
import { useKeycloak } from "../KeycloakProvider";
import { usePlayerColors } from "../main_dashboard_components/PlayerColorContext";
import "./PlayerFeatureTable.css";
import "./PredictionResultsTable.css";
import { getPlayerId } from "../components/PlayerLookup";

const PredictionResultsTable = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [kpiData, setKpiData] = useState([]);

  // Other filters (removed the old single-select "feature")
  const [filters, setFilters] = useState({
    featureProbability: 60,
    teamAbbreviation: "",
    opponent: "",
    betAmount: 100,
  });

  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [useAmericanOdds, setUseAmericanOdds] = useState(true);

  const { token } = useKeycloak();
  const { setSelectedPlayer } = usePlayerColors();
  const navigate = useNavigate();

  // List of all available features with labels
  const featureOptions = [
    { label: "Points", value: "pts" },
    { label: "Assists", value: "ast" },
    { label: "Rebounds", value: "reb" },
    { label: "Pts+Ast", value: "pts_ast" },
    { label: "Pts+Reb", value: "pts_reb" },
    { label: "Ast+Reb", value: "ast_reb" },
    { label: "Pts+Ast+Reb", value: "pts_ast_reb" },
    { label: "Steals", value: "stl" },
    { label: "Blocks", value: "blk" },
    { label: "Threes", value: "fg3m" },
  ];

  // Multi-select state: default to ["pts", "ast", "reb"]
  const [selectedFeatures, setSelectedFeatures] = useState(["pts", "ast", "reb"]);
  // Control for toggling the dropdown list
  const [featureDropdownOpen, setFeatureDropdownOpen] = useState(false);

  const toggleFeatureDropdown = () => {
    setFeatureDropdownOpen(!featureDropdownOpen);
  };

  // Toggle selection: if already selected, remove it; if not, add it.
  const handleFeatureCheckboxChange = (value) => {
    setSelectedFeatures((prev) => {
      if (prev.includes(value)) {
        return prev.filter((f) => f !== value);
      } else {
        return [...prev, value];
      }
    });
  };

  // --------------------------------
  // 1) On mount, read "americanOdds" from localStorage
  useEffect(() => {
    const storedValue = localStorage.getItem("americanOdds");
    if (storedValue !== null) {
      setUseAmericanOdds(JSON.parse(storedValue));
    }
  }, []);

  // 2) Listen for "oddsChange" event
  useEffect(() => {
    const handleOddsChange = () => {
      const storedValue = localStorage.getItem("americanOdds");
      if (storedValue !== null) {
        setUseAmericanOdds(JSON.parse(storedValue));
      }
    };
    window.addEventListener("oddsChange", handleOddsChange);

    return () => {
      window.removeEventListener("oddsChange", handleOddsChange);
    };
  }, []);
  // --------------------------------

  // Convert decimal (European) odds to American odds
  const convertToAmericanOdds = (decimalOdds) => {
    if (!decimalOdds || isNaN(decimalOdds)) return "";
    const val = parseFloat(decimalOdds);
    if (val >= 2.0) {
      return `+${Math.round((val - 1) * 100)}`;
    } else {
      return `${Math.round(-100 / (val - 1))}`;
    }
  };

  // Fetch data (runs once token is available)
  useEffect(() => {
    const fetchPlayerData = async () => {
      const queryParams = new URLSearchParams({ latest: "true" });
      const endpoint = `player-prediction-results/filter_predictions/?${queryParams.toString()}`;

      try {
        const response = await fetchData(endpoint, token);
        setData(response);
        setFilteredData(response);
        setKpiData(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (token) fetchPlayerData();
  }, [token]);

  // Update filters for the other inputs
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  // Filter and sort data. Now we filter by whether row.feature is among the selected features.
  useEffect(() => {
    let filtered = [...data];
    let kpiFilteredData = [...data];

    if (filters.featureProbability) {
      const minProb = parseFloat(filters.featureProbability);
      filtered = filtered.filter(
        (row) => row.feature_probability * 100 >= minProb
      );
      kpiFilteredData = kpiFilteredData.filter(
        (row) => row.feature_probability * 100 >= minProb
      );
    }

    // Only include rows whose feature is one of the selected ones.
    if (selectedFeatures && selectedFeatures.length > 0) {
      filtered = filtered.filter((row) =>
        selectedFeatures.includes(row.feature)
      );
      kpiFilteredData = kpiFilteredData.filter((row) =>
        selectedFeatures.includes(row.feature)
      );
    }

    if (filters.teamAbbreviation) {
      filtered = filtered.filter((row) =>
        row.team_abbreviation
          ?.toLowerCase()
          .includes(filters.teamAbbreviation.toLowerCase())
      );
    }

    if (filters.opponent) {
      filtered = filtered.filter((row) =>
        row.next_opponent?.toLowerCase().includes(filters.opponent.toLowerCase())
      );
    }

    if (sortConfig.key) {
      filtered.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }

    setFilteredData(filtered);
    setKpiData(kpiFilteredData);
  }, [filters, data, sortConfig, selectedFeatures]);

  // Sorting handler
  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
  };

  // Navigate to Player Dashboard when a row is clicked
  const handlePlayerClick = (playerId) => {
    setSelectedPlayer(getPlayerId(playerId));
    navigate("/PlayerDashboard");
  };

  // KPI calculations (using all featureOptions, regardless of selection)
  const calculateMetrics = (data, betAmount) => {
    const metrics = featureOptions.reduce(
      (acc, option) => {
        const featureData = data.filter((row) => row.feature === option.value);
        const numGames = featureData.length;
        const totalHits = featureData.filter((row) => row.hit).length;

        const accuracy = numGames > 0 ? (totalHits / numGames) * 100 : 0;
        const profit = featureData.reduce((sum, row) => {
          return sum + (row.hit ? betAmount * (row.odds - 1) : -betAmount);
        }, 0);

        acc[option.value] = {
          label: option.label,
          accuracy: accuracy.toFixed(1),
          profit: Math.round(profit),
          games: numGames,
        };
        return acc;
      },
      { total: { label: "Total", accuracy: 0, profit: 0, games: 0 } }
    );

    const totalHits = data.filter((row) => row.hit).length;
    const totalProfit = data.reduce((sum, row) => {
      return sum + (row.hit ? betAmount * (row.odds - 1) : -betAmount);
    }, 0);

    metrics.total.accuracy =
      data.length > 0 ? ((totalHits / data.length) * 100).toFixed(1) : 0;
    metrics.total.profit = Math.round(totalProfit);
    metrics.total.games = data.length;

    return metrics;
  };

  const metrics = calculateMetrics(kpiData, filters.betAmount);

  return (
    <div className="player-feature-table-container">
      <h1>Yesterday's Results</h1>

      {/* KPI Cards: Only show the "Total" card and cards for selected features */}
      <div className="kpi-cards">
        {Object.entries(metrics)
          .filter(([key]) => key === "total" || selectedFeatures.includes(key))
          .map(([key, value]) => (
            <div key={key} className="kpi-card">
              <p>{value.label}</p>
              <p>
                Accuracy:{" "}
                <span className="kpi-card-accuracy-value">{value.accuracy}%</span>
              </p>
              <p>
                Profit:{" "}
                <span
                  className={`kpi-card-profit-value ${
                    value.profit >= 0 ? "positive" : "negative"
                  }`}
                >
                  ${value.profit}
                </span>
              </p>
              <p>Games: {value.games}</p>
            </div>
          ))}
      </div>

      {/* Filters */}
      <div className="projection-filters">
        <div className="filter-group">
          <label htmlFor="betAmount">Bet Amount</label>
          <input
            type="number"
            id="betAmount"
            name="betAmount"
            value={filters.betAmount}
            onChange={handleFilterChange}
          />
        </div>

        <div className="filter-group">
          <label htmlFor="featureProbability">Confidence</label>
          <input
            type="number"
            id="featureProbability"
            name="featureProbability"
            min="0"
            max="100"
            value={filters.featureProbability}
            onChange={handleFilterChange}
          />
        </div>

        {/* Custom multi-select dropdown for Features */}
        <div className="hr-filter-item">
          <label>Features</label>
          {/* Clickable control styled with HR classes */}
          <div className="hr-multi-select-control" onClick={toggleFeatureDropdown}>
            <span>
              {selectedFeatures.length === 0
                ? "None selected"
                : `${selectedFeatures.length} selected`}
            </span>
            <div className="hr-multi-select-arrow" />
          </div>
          {featureDropdownOpen && (
            <div className="hr-multi-select-options">
              {featureOptions.map((feat) => (
                <div key={feat.value} className="hr-option">
                  <label>
                    <input
                      type="checkbox"
                      checked={selectedFeatures.includes(feat.value)}
                      onChange={() => handleFeatureCheckboxChange(feat.value)}
                    />
                    {feat.label}
                  </label>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="filter-group">
          <label htmlFor="teamAbbreviation">Team Abbreviation</label>
          <input
            type="text"
            id="teamAbbreviation"
            name="teamAbbreviation"
            value={filters.teamAbbreviation}
            onChange={handleFilterChange}
          />
        </div>

        <div className="filter-group">
          <label htmlFor="opponent">Opponent</label>
          <input
            type="text"
            id="opponent"
            name="opponent"
            value={filters.opponent}
            onChange={handleFilterChange}
          />
        </div>
      </div>

      {filteredData.length > 0 ? (
        <div className="table-wrapper">
          <table className="player-feature-table">
            <thead>
              <tr>
                <th onClick={() => handleSort("player_name")}>Player Name</th>
                <th onClick={() => handleSort("team_abbreviation")}>Team</th>
                <th onClick={() => handleSort("next_opponent")}>Opponent</th>
                <th onClick={() => handleSort("feature")}>Prop</th>
                <th onClick={() => handleSort("sportsbook")}>Sportsbook</th>
                <th onClick={() => handleSort("threshold")}>Threshold</th>
                <th onClick={() => handleSort("odds")}>Odds</th>
                <th onClick={() => handleSort("feature_prediction")}>
                  Projection
                </th>
                <th onClick={() => handleSort("true_value")}>Result</th>
                <th onClick={() => handleSort("feature_probability")}>
                  Confidence
                </th>
                <th>Won</th>
                <th>Profit</th>
                <th>Hit</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((row, index) => {
                // Convert odds if American is enabled
                const displayedOdds = useAmericanOdds
                  ? convertToAmericanOdds(row.odds)
                  : parseFloat(row.odds)?.toFixed(2);

                const wonAmount = row.hit ? filters.betAmount * row.odds : 0;
                const profit = row.hit
                  ? filters.betAmount * (row.odds - 1)
                  : -filters.betAmount;

                return (
                  <tr key={index}>
                    <td onClick={() => handlePlayerClick(row.id)} className="clickable">
                      {row.player_name}
                    </td>
                    <td>{row.team_abbreviation}</td>
                    <td>{row.next_opponent}</td>
                    <td>{row.feature}</td>
                    <td>{row.sportsbook}</td>
                    <td>{row.threshold}</td>
                    <td>{displayedOdds}</td>
                    <td>{Math.round(row.feature_prediction)}</td>
                    <td>{row.true_value}</td>
                    <td>{(row.feature_probability * 100).toFixed(1)}%</td>
                    <td>${Math.round(wonAmount)}</td>
                    <td>${Math.round(profit)}</td>
                    <td>{row.hit ? "✔️" : "❌"}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <p>No data available to display.</p>
      )}
    </div>
  );
};

export default PredictionResultsTable;
