import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePlayerColors } from "../main_dashboard_components/PlayerColorContext";
import { getPlayerId } from "../components/PlayerLookup";
import { abbreviationToTeamName } from "../components/TeamDictionary";
import ShowstoneIcon from "../assets/ShowstoneIcon.png";
import "./TopPickCard.css";

export default function TopPickCard({
  playerName,
  feature,
  lineValue,
  predicted,
  probability,
  l15,
  l5Average,
  percentageChange,
  overThreshold,
  totalBars,
  barValues,
  impact,
  nextOpponent,
  rollingValue2Mean,
  rollingHome15,
  minutesDiff,
  injuredPlayersDetails,
  isGold = false,
  isFire = false
}) {
  const [isFlipped, setIsFlipped] = useState(false);
  const navigate = useNavigate();
  const { setSelectedPlayer } = usePlayerColors();

  const safeName = typeof playerName === "string" ? playerName : "Unknown";
  const formattedName =
    safeName.length > 16
      ? `${safeName.split(" ")[0][0]}. ${safeName.split(" ")[1] || ""}`
      : safeName;

  const isOverValue = (predicted || 0) > (lineValue || 0);
  const featureTitle = feature
    .toUpperCase()
    .split("_")
    .map((word) => word.charAt(0) + word.slice(1))
    .join("+");
  const statusLabel = isOverValue ? "Over" : "Under";
  const arrow = isOverValue ? "↑" : "↓";

  const maxBarValue = Math.max(...barValues.map((entry) => entry.value), lineValue || 1);
  const CHART_HEIGHT = 120;
  const scaleFactor = CHART_HEIGHT / maxBarValue;
  const minutesDiffStr =
    minutesDiff > 0 ? `+${minutesDiff.toFixed(2)}` : minutesDiff.toFixed(2);
  const impactStr = impact > 0 ? `+${impact.toFixed(2)}` : impact.toFixed(2);

  // Convert probability (e.g. "70.0%") to a number.
  const confidence = parseFloat(probability);

  // Determine whether to show the accuracy emoji.
  const feat = feature.toLowerCase();
  let showAccuracy = false;
  if (feat === "pts" && confidence > 80 && !isOverValue) {
    showAccuracy = true;
  } else if ((feat === "reb" || feat === "ast") && confidence > 70) {
    showAccuracy = true;
  }

  function parseInjuries(details) {
    if (!details) return [];
    return details
      .split(";")
      .map((entry) => {
        const match = entry.trim().match(
          /^(.+?) \(impact: ([-\d.]+), games_without: (\d+)\)$/
        );
        return match
          ? {
              name: match[1],
              impact: parseFloat(match[2]),
              games: parseInt(match[3], 10)
            }
          : null;
      })
      .filter(Boolean);
  }

  function formatName(fullName) {
    const parts = fullName.split(" ");
    if (parts.length > 1) {
      const lastName = parts[parts.length - 1];
      return lastName.length > 10 ? lastName : `${parts[0][0]}. ${lastName}`;
    }
    return fullName;
  }

  const injuries = parseInjuries(injuredPlayersDetails);

  const handleNameLinkClick = (e) => {
    if (e.button === 1 || e.metaKey || e.ctrlKey || e.altKey || e.shiftKey) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    const pid = getPlayerId(safeName);
    setSelectedPlayer(pid);
    navigate(`/PlayerDashboard?playerId=${encodeURIComponent(pid)}`);
  };

  return (
    <div className="top-pick-card" onClick={() => setIsFlipped(!isFlipped)}>
      {/* Wrap the inner container in a flip-container to provide perspective */}
      <div className="flip-container">
        <div className={`top-pick-card-inner ${isFlipped ? "flipped" : ""}`}>
          {/* FRONT FACE: Overlays are placed here so they show only on the front */}
          <div className="top-pick-card-face front">
            {isGold && (
              <div className="overlay gold-overlay" onClick={(e) => e.stopPropagation()}>
                <span className="overlay-tooltip">Showstone Top Pick</span>
                <img src={ShowstoneIcon} alt="Gold Pick" className="overlay-icon" />
              </div>
            )}
            {isFire && (
              <div className="overlay fire-overlay" onClick={(e) => e.stopPropagation()}>
                <span className="overlay-tooltip">Community Hot Pick</span>
                <span className="overlay-icon">🔥</span>
              </div>
            )}
            {showAccuracy && (
              <div className="overlay accuracy-overlay" onClick={(e) => e.stopPropagation()}>
                <span className="overlay-tooltip">Historical High Accuracy</span>
                <span className="overlay-icon">🎯</span>
              </div>
            )}
            <div className="figma-background-layer"></div>
            <h3 className="top-pick-card-title">
              <a
                href={`/PlayerDashboard?playerId=${encodeURIComponent(getPlayerId(safeName))}`}
                onClick={handleNameLinkClick}
                style={{ cursor: "pointer", color: "inherit", textDecoration: "none" }}
                onMouseEnter={(e) => (e.target.style.color = "#0ba59d")}
                onMouseLeave={(e) => (e.target.style.color = "inherit")}
              >
                {formattedName}
              </a>
            </h3>
            <p className="top-pick-card-feature">
              {featureTitle}{" "}
              <span className={isOverValue ? "over" : "under"}>
                {arrow}{statusLabel}
              </span>
            </p>
            <div className="top-pick-card-values">
              <div className="top-pick-card-stat">
                Line <span>{lineValue.toFixed(1)}</span>
              </div>
              <div className="top-pick-card-stat">
                Proj <span>{predicted.toFixed(1)}</span>
              </div>
              <div className="top-pick-card-stat">
                Conf <span>{probability}</span>
              </div>
              <div className="top-pick-card-stat">
                L15 <span>{l15}</span>
              </div>
            </div>
            <div className="top-pick-card-divider" />
            <div className="top-pick-card-content">
              <div className="top-pick-card-l5-pill">
                L5 <span className="pill-value">{l5Average.toFixed(1)}</span>
                <span className={`top-pick-card-percentage ${parseFloat(percentageChange) >= 0 ? "positive" : "negative"}`}>
                  {percentageChange}%
                </span>
              </div>
              <div className="top-pick-card-chart-container">
                <div className="top-pick-card-chart-label">
                  {overThreshold}/{totalBars}
                </div>
                <div className="top-pick-card-line" style={{ bottom: `${(lineValue || 0) * scaleFactor - 2}px` }} />
                <div className="top-pick-card-bars">
                  {barValues.map((entry, idx) => (
                    <div
                      key={idx}
                      className={`top-pick-card-bar ${entry.value >= lineValue ? "above-line" : ""}`}
                      style={{ height: `${entry.value * scaleFactor}px` }}
                      title={`Date: ${entry.date.toDateString()}\nPts: ${entry.pts}, Reb: ${entry.reb}, Ast: ${entry.ast}\nMin: ${entry.min}\nValue: ${entry.value}`}
                    />
                  ))}
                </div>
              </div>
              <div
                className="flip-icon"
                onClick={(e) => { e.stopPropagation(); setIsFlipped(!isFlipped); }}
                style={{ position: "absolute", top: "-125px", right: "10px", fontSize: "24px", cursor: "pointer", color: "var(--main-color)" }}
                title="Flip card"
              >
                ↻
              </div>
            </div>
          </div>
          {/* BACK FACE */}
          <div className="top-pick-card-face back">
            <div
              className="flip-icon"
              onClick={(e) => { e.stopPropagation(); setIsFlipped(!isFlipped); }}
              style={{ position: "absolute", top: "10px", right: "10px", fontSize: "24px", cursor: "pointer", color: "rgb(117,117,117)" }}
              title="Flip card"
            >
              ↻
            </div>
            <h3 className="top-pick-card-back-card-title">{formattedName}</h3>
            <p className="top-pick-card-back-card-feature">
              {featureTitle}{" "}
              <span className={isOverValue ? "over" : "under"}>
                {arrow}{statusLabel}
              </span>
            </p>
            <div className="top-pick-card-back-stats">
              <p>
                <strong>Last 2 Vs {abbreviationToTeamName[nextOpponent]}:</strong>{" "}
                {rollingValue2Mean.toFixed(2)} {feature}
              </p>
              <p>
                <strong>Minutes (L5 vs L15):</strong> {minutesDiffStr} min
              </p>
              <p>
                <strong>Home/Away L15:</strong> {rollingHome15.toFixed(2)} {feature}
              </p>
              <h3 className="top-pick-card-injury-title">Injury Impact</h3>
              <div className="top-pick-card-injury-details">
                <div className="top-pick-card-injury-header">
                  <span>Name</span>
                  <span>Impact</span>
                  <span># Games</span>
                </div>
                {injuries.map((injury, index) => (
                  <div key={index} className="top-pick-card-injury-row">
                    <span>{formatName(injury.name)}</span>
                    <span>{injury.impact}</span>
                    <span>{injury.games}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
