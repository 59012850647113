import React, { useEffect, useState } from 'react';
import './BlogPostList.css';  // We'll define some CSS for styling

function BlogPostsList() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    fetch('/api/blog/blogposts/')
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch blog posts');
        }
        return response.json();
      })
      .then(data => {
        setPosts(data);
      })
      .catch(error => {
        console.error('Error fetching blog posts:', error);
      });
  }, []);

  return (
    <div className="blog-posts-list">
      <h1>Blog Posts</h1>
      {posts.map(post => (
        <div key={post.post_id} className="blog-post-item">
          <h2 className="blog-post-title">{post.title}</h2>
          {/* Render the HTML from Quill */}
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: post.content }}
          />
          <p className="blog-post-meta">
            <strong>Author:</strong> {post.author} <br />
            <strong>Created:</strong> {post.created_at}
          </p>
        </div>
      ))}
    </div>
  );
}

export default BlogPostsList;
