import React from 'react';
import './Matchup.css';

const Matchup = ({
  team1Logo,
  team2Logo,
  team1Name,
  team2Name,
  time,
  topPicks,
  isHomeGame, // Boolean to decide if it's a home game (@) or neutral game (vs)
}) => {
  return (
    <div className="matchup-container">
      {/* Matchup Details */}
      <div className="matchup-details">
        {/* Team 1 */}
        <div className="team-container">
          <img src={team2Logo} alt={`${team2Name} logo`} className="team-logo" />
          <span className="team-name">{team2Name}</span>
        </div>

        {/* Versus or Home/Away Indicator */}
        <span className="vs">{isHomeGame ? '@' : 'vs'}</span>

        {/* Team 2 */}
        <div className="team-container">
          <img src={team1Logo} alt={`${team1Name} logo`} className="team-logo" />
          <span className="team-name">{team1Name}</span>
        </div>

        {/* Picks Statistics */}
        <div className="pick-stats">
          <span className="top-picks">{topPicks} Top Picks</span>
        </div>
      </div>

      {/* Matchup Time and Home Team */}
      <div className="matchup-bottom">
        <div className="matchup-time">{time}</div>
        {isHomeGame && <div className="home-team">@{team1Name}</div>}
      </div>
    </div>
  );
};

export default Matchup;
