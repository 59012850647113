import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { fetchData } from "../api"; // Your API utility
import { useKeycloak } from "../KeycloakProvider"; // Provides 'token'
import { useNavigate, Link } from "react-router-dom";

// Replace with your actual image import
import showstoneLogoWhite from "../assets/WhiteIcon.png"; // Adjust path as needed
import "./SubscriptionPage.css"; // Make sure this file has .subscription-page { font-family: "Montserrat", ... }

// PayPal client ID
const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;

// Two plan IDs: one with trial, one without trial
const PAYPAL_PLAN_ID_TRIAL = "P-8KD33843RT670000UM6UKARY"; // <-- YOUR trial plan ID
const PAYPAL_PLAN_ID_NO_TRIAL = "P-55T58018M3594601TM6UKBQI"; // <-- YOUR no-trial plan ID

// Example country codes
const SERBIA_COUNTRY_CODE = "X";
const US_COUNTRY_CODE = "US";

// Toggle to show/hide the "Free Trial" button
const trialEnabled = true;

const SubscriptionPage = () => {
  const paypalRef = useRef(null);
  const { token } = useKeycloak();
  const navigate = useNavigate();

  // Subscription states
  const [isSubscribed, setIsSubscribed] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [expirationDate, setExpirationDate] = useState(null);
  const [loadingSubscription, setLoadingSubscription] = useState(true);
  const [statusMessage, setStatusMessage] = useState("");

  // PayPal script loading
  const [scriptLoaded, setScriptLoaded] = useState(false);

  // Geo states
  const [userCountry, setUserCountry] = useState(null);
  const [geoLoading, setGeoLoading] = useState(true);

  // Selected plan – default to "Subscribe" (no trial)
  const [selectedPlanId, setSelectedPlanId] = useState(PAYPAL_PLAN_ID_NO_TRIAL);

  // Extract user ID from token (replace with your actual user ID logic)
  const auth0_id = token ? JSON.parse(atob(token.split(".")[1])).sub : null;

  // --------------------------------------
  // 1. Fetch user country
  // --------------------------------------
  const getUserCountry = async () => {
    try {
      setGeoLoading(true);
      const response = await axios.get("https://ipapi.co/json/");
      if (response.data && response.data.country) {
        setUserCountry(response.data.country);
      } else {
        setUserCountry(null);
      }
    } catch (error) {
      console.error("Error fetching country from ipapi.co:", error);
      setUserCountry(null);
    } finally {
      setGeoLoading(false);
    }
  };

  // --------------------------------------
  // 2. Subscription API Calls
  // --------------------------------------
  const savePurchase = async (purchaseData) => {
    try {
      // This will POST to your backend to create/update the subscription
      await fetchData("subscription/save_purchase/", token, "POST", purchaseData);
    } catch (error) {
      console.error("Error saving subscription:", error);
      alert(`Error saving subscription: ${error.message}`);
    }
  };

  const cancelSubscription = async () => {
    try {
      const response = await fetchData(
        "subscription/cancel_subscription/",
        token,
        "POST",
        { auth0_id }
      );
      if (response.status === "success") {
        alert("Subscription canceled successfully.");
        setSubscriptionStatus("cancelled_but_valid_until");
        setStatusMessage(
          "Thank you for being a premium member. Your subscription is successfully cancelled. You have premium access until your next billing date."
        );
      } else {
        alert(`Error canceling subscription: ${response.message}`);
      }
    } catch (error) {
      console.error("Error canceling subscription:", error);
      alert("Error canceling subscription. Please try again.");
    }
  };

  const reactivateSubscription = async () => {
    try {
      const response = await fetchData(
        "subscription/reactivate_subscription/",
        token,
        "POST",
        { auth0_id }
      );
      if (response.status === "success") {
        alert("Subscription reactivated successfully.");
        setSubscriptionStatus("active");
        setStatusMessage("Thank you for reactivating your subscription!");
      } else {
        alert(`Error reactivating subscription: ${response.message}`);
      }
    } catch (error) {
      console.error("Error reactivating subscription:", error);
      alert("Error reactivating subscription. Please try again.");
    }
  };

  // Pause & Unpause
  const pauseSubscription = async () => {
    try {
      const response = await fetchData(
        "subscription/pause_subscription/",
        token,
        "POST",
        { auth0_id }
      );
      if (response.status === "success") {
        alert("Subscription paused successfully.");
        setSubscriptionStatus("paused");
        setStatusMessage("Your subscription is currently paused.");
      } else {
        alert(`Error pausing subscription: ${response.message}`);
      }
    } catch (error) {
      console.error("Error pausing subscription:", error);
      alert("Error pausing subscription. Please try again.");
    }
  };

  const unpauseSubscription = async () => {
    try {
      const response = await fetchData(
        "subscription/unpause_subscription/",
        token,
        "POST",
        { auth0_id }
      );
      if (response.status === "success") {
        alert("Subscription unpaused successfully.");
        setSubscriptionStatus("active");
        setStatusMessage("Your subscription is back to active!");
      } else {
        alert(`Error unpausing subscription: ${response.message}`);
      }
    } catch (error) {
      console.error("Error unpausing subscription:", error);
      alert("Error unpausing subscription. Please try again.");
    }
  };

  const checkSubscriptionStatus = async () => {
    if (!auth0_id) {
      setIsSubscribed(false);
      setLoadingSubscription(false);
      return;
    }
    try {
      setLoadingSubscription(true);
      const statusData = await fetchData(
        `subscription/subscription_status/?auth0_id=${encodeURIComponent(auth0_id)}`,
        token
      );

      if (statusData) {
        const nextDate = statusData.next_billing_date
          ? new Date(statusData.next_billing_date)
          : null;
        const now = new Date();

        if (nextDate && nextDate < now) {
          setIsSubscribed(false);
          setSubscriptionStatus("inactive");
          setStatusMessage("Your subscription has expired. Please subscribe again.");
          setExpirationDate(null);
          setLoadingSubscription(false);
          return;
        }

        if (statusData.status === "active") {
          setIsSubscribed(true);
          setSubscriptionStatus("active");
          setExpirationDate(nextDate || null);
          setStatusMessage("Thank you for being a premium member!");
        } else if (statusData.status === "paused") {
          setIsSubscribed(true);
          setSubscriptionStatus("paused");
          setExpirationDate(nextDate || null);
          setStatusMessage("Your subscription is currently paused.");
        } else if (statusData.status === "cancelled_but_valid_until") {
          setIsSubscribed(true);
          setSubscriptionStatus("cancelled_but_valid_until");
          setExpirationDate(nextDate || null);
          setStatusMessage(
            "Thank you for being a premium member. Your subscription is successfully cancelled. You have premium access until your next billing date."
          );
        } else {
          setIsSubscribed(false);
          setSubscriptionStatus("inactive");
          setStatusMessage(statusData.message || "No active subscription found.");
        }
      } else {
        setIsSubscribed(false);
        setSubscriptionStatus("inactive");
        setStatusMessage("No subscription data found.");
      }
    } catch (error) {
      console.error("Error checking subscription status:", error);
      setIsSubscribed(false);
    } finally {
      setLoadingSubscription(false);
    }
  };

  // --------------------------------------
  // 3. useEffects
  // --------------------------------------
  // 3a. On mount, fetch country & check subscription
  useEffect(() => {
    getUserCountry();
    if (token && auth0_id) {
      checkSubscriptionStatus();
    } else {
      setIsSubscribed(false);
      setLoadingSubscription(false);
    }
  }, [token, auth0_id]);

  // 3b. Load PayPal script if not restricted
  useEffect(() => {
    if (geoLoading) return;
    if (!userCountry) return;
    if (userCountry === SERBIA_COUNTRY_CODE) return; // restricted

    const existingScript = document.getElementById("paypal-script");
    if (!existingScript) {
      const script = document.createElement("script");
      script.id = "paypal-script";

      if (userCountry === US_COUNTRY_CODE) {
        script.src = `https://www.paypal.com/sdk/js?client-id=${PAYPAL_CLIENT_ID}&vault=true&intent=subscription&enable-funding=venmo,card&currency=EUR`;
      } else {
        script.src = `https://www.paypal.com/sdk/js?client-id=${PAYPAL_CLIENT_ID}&vault=true&intent=subscription&enable-funding=venmo,card&currency=EUR`;
      }

      script.async = true;
      script.onload = () => setScriptLoaded(true);
      document.body.appendChild(script);
    } else {
      if (window.paypal) {
        setScriptLoaded(true);
      }
    }
  }, [userCountry, geoLoading]);

  // 3c. Render PayPal button after plan is selected
  useEffect(() => {
    if (
      !geoLoading &&
      userCountry &&
      userCountry !== SERBIA_COUNTRY_CODE &&
      scriptLoaded &&
      !loadingSubscription &&
      !isSubscribed &&
      selectedPlanId &&
      window.paypal &&
      paypalRef.current
    ) {
      // Clear existing button if re-rendering
      paypalRef.current.innerHTML = "";

      try {
        window.paypal
  .Buttons({
    style: {
      shape: "rect",
      color: "gold",
      layout: "vertical",
      label: "subscribe",
    },
    createSubscription: (data, actions) => {
      return actions.subscription.create({
        plan_id: selectedPlanId,
        application_context: {
          // When the subscription is approved, PayPal will redirect the user to this URL.
          return_url: window.location.origin + "/PostSubscription",
          // Optional: If the user cancels, redirect here.
          cancel_url: window.location.origin + "/subscription",
        },
      });
    },
    onApprove: async (data, actions) => {
      try {
        // If you are using return_url redirection, onApprove might not be called.
        // However, if you are in an in-context (popup) flow, you can still use it.
        const details = await actions.subscription.get();

        const isTrial = selectedPlanId === PAYPAL_PLAN_ID_TRIAL;
        const purchaseData = {
          auth0_id,
          subscription_id: data.subscriptionID,
          paypal_id: data.subscriptionID,
          user_email: details.subscriber.email_address,
          plan_id: details.plan_id,
          start_date: details.start_time,
          next_billing_date: details.billing_info.next_billing_time,
          is_trial: isTrial,
        };

        alert(`Subscription successful! ID: ${data.subscriptionID}`);
        await savePurchase(purchaseData);

        setIsSubscribed(true);
        setSubscriptionStatus("active");
        setExpirationDate(details.billing_info.next_billing_time);
        setStatusMessage("Thank you for being a premium member!");

        // If using in-context approval, navigate to your confirmation page.
        navigate("/PostSubscription");
      } catch (err) {
        console.error("Error in onApprove:", err);
        alert("Error handling PayPal subscription. Please try again.");
      }
    },
    onError: (err) => {
      console.error("PayPal Subscription Error:", err);
      alert("Error with the PayPal subscription. Please try again.");
    },
  })
  .render(paypalRef.current);

      } catch (error) {
        console.error("Error rendering PayPal button:", error);
      }
    }
  }, [
    geoLoading,
    userCountry,
    scriptLoaded,
    loadingSubscription,
    isSubscribed,
    selectedPlanId,
    navigate,
  ]);

  // --------------------------------------
  // 4. Render
  // --------------------------------------
  return (
    <div className="subscription-page">
      <div className="subscription-page-container">
        <h1>Premium Subscription</h1>

        {/* Promotional text (shown only if not subscribed) */}
        {!isSubscribed && (
          <>
            <p className="subscription-page-early-dev-text">
              We are currently in early development. By subscribing to our Premium tier,
              you'll directly support our growth and enable us to roll out more advanced
              features, deliver fresh content, and improve your overall experience.
            </p>
            <p className="subscription-page-early-dev-text2">
              Want to test premium features before committing? Click on the Free Trial button below to enjoy a 7-day trial for free!
            </p>
            <p className="subscription-page-early-dev-text2">
              If you want to check out our model's historical performance, you can do it here{" "}
              <Link to="/HistoricalResults" className="nav-link">
                Model Performance
              </Link>
            </p>
          </>
        )}

        {geoLoading && <p>Determining your location...</p>}

        {/* Blocked country message */}
        {!geoLoading && userCountry === SERBIA_COUNTRY_CODE && (
          <p>Premium is not supported in your country at the moment.</p>
        )}

        {/* Message for non-logged in users */}
        {!geoLoading && userCountry !== SERBIA_COUNTRY_CODE && !auth0_id && (
          <p>Please log in before subscribing.</p>
        )}

        {/* Loading subscription status */}
        {loadingSubscription && !geoLoading && <p>Checking subscription status...</p>}

        {/* Pricing card shown if not subscribed */}
        {!loadingSubscription &&
          !geoLoading &&
          userCountry !== SERBIA_COUNTRY_CODE &&
          !isSubscribed &&
          auth0_id && (
            <section className="subscription-page-pricing-section">
              <div className="subscription-page-pricing-cards-container">
                {/* PREMIUM CARD */}
                <div className="subscription-page-pricing-card subscription-page-premium-card">
                  {/* Overlapping Logo (optional) */}
                  <div className="subscription-page-card-logo-container">
                    <img
                      src={showstoneLogoWhite}
                      alt="Showstone Logo"
                      className="subscription-page-card-logo"
                    />
                  </div>

                  <div className="subscription-page-pricing-card-header">
                    <span className="subscription-page-card-tier">MONTHLY PREMIUM</span>
                  </div>

                  <div className="subscription-page-pricing-card-body">
                    <h3 className="subscription-page-pricing-card-price">$14.99</h3>
                    <ul className="subscription-page-pricing-features-list">
                    <li>Model Projections</li>
                    <li>Advanced Features</li>
                    <li>Premium Discord Group With Our Expert Analysts</li>
                    <li>Priority Customer Support</li>
                    </ul>
                  </div>

                  <div className="subscription-page-pricing-card-footer">
                    {/* Subscribe Button */}
                    <button
                      className="subscription-page-pricing-card-button subscription-page-subscribe-button"
                      onClick={() => setSelectedPlanId(PAYPAL_PLAN_ID_NO_TRIAL)}
                      style={{
                        backgroundColor:
                          selectedPlanId === PAYPAL_PLAN_ID_NO_TRIAL
                            ? "var(--main-color)"
                            : "#f1c50e",
                        color:
                        selectedPlanId === PAYPAL_PLAN_ID_NO_TRIAL
                          ? "white"
                          : "black",
                      }}
                    >
                      Subscribe
                    </button>

                    {/* Free Trial Button */}
                    {trialEnabled && (
                      <button
                        className="subscription-page-pricing-card-button"
                        onClick={() => setSelectedPlanId(PAYPAL_PLAN_ID_TRIAL)}
                        style={{
                          backgroundColor:
                            selectedPlanId === PAYPAL_PLAN_ID_TRIAL
                              ? "var(--main-color)"
                              : "#f1c50e",
                          color:
                          selectedPlanId === PAYPAL_PLAN_ID_TRIAL
                            ? "white"
                            : "black",
                        }}
                      >
                        Free Trial
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <p className="subscription-page-legal-disclaimer" style={{ marginTop: "20px" }}>
                By subscribing, you agree to our{" "}
                <a href="/TermsOfService" target="_blank" rel="noopener noreferrer">
                  Terms &amp; Conditions
                </a>{" "}
                and acknowledge our{" "}
                <a href="/PrivacyPolicy" target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                </a>
                .
              </p>

              {selectedPlanId && (
                <p
                  style={{
                    marginTop: "20px",
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "#333",
                  }}
                >
                  {selectedPlanId === PAYPAL_PLAN_ID_TRIAL
                    ? "By subscribing you are agreeing to a 7-day free trial, then $14.99 monthly charge upon expiration of trial."
                    : "By subscribing you are agreeing to a $14.99 monthly charge."}
                </p>
              )}

              {/* PayPal Button Container */}
              <div
                className="subscription-page-paypal-button-container"
                ref={paypalRef}
                style={{ marginTop: "20px" }}
              />
            </section>
          )}

        {/* If subscription is active => Pause/Cancel */}
        {subscriptionStatus === "active" && (
          <div>
            <p className="subscription-page-success-message">{statusMessage}</p>
            {expirationDate && (
              <p>
                Your subscription renews on{" "}
                <b>{new Date(expirationDate).toLocaleDateString()}</b>.
              </p>
            )}
            <button
              className="subscription-page-action-button"
              onClick={pauseSubscription}
            >
              Pause Subscription
            </button>
            <button
              className="subscription-page-action-button"
              onClick={cancelSubscription}
            >
              Cancel Subscription
            </button>
          </div>
        )}

        {/* If subscription is paused => Unpause/Cancel */}
        {subscriptionStatus === "paused" && (
          <div>
            <p className="subscription-page-warning-message">{statusMessage}</p>
            {expirationDate && (
              <p>
                It will next bill on{" "}
                <b>{new Date(expirationDate).toLocaleDateString()}</b> if you resume.
              </p>
            )}
            <button
              className="subscription-page-action-button"
              onClick={unpauseSubscription}
            >
              Unpause Subscription
            </button>
            <button
              className="subscription-page-action-button"
              onClick={cancelSubscription}
            >
              Cancel Subscription
            </button>
          </div>
        )}

        {/* If cancelled but still valid => (No Reactivate button) */}
        {subscriptionStatus === "cancelled_but_valid_until" && (
          <div>
            <p className="subscription-page-warning-message">{statusMessage}</p>
            {expirationDate && (
              <p>
                You still have access until{" "}
                <b>{new Date(expirationDate).toLocaleDateString()}</b>.
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SubscriptionPage;
