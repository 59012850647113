import React from 'react';
import PreviousBetsTable from '../ticket_watcher_components/PreviousBets';
import './TicketWatcher.css';
import { useKeycloak } from '../KeycloakProvider';
function TicketWatcher() {
  const { token, keycloakId } = useKeycloak();
  return (
    <div className="ticket-watcher-container">
      <PreviousBetsTable
      keycloakId={keycloakId}
      token={token}
      />
    </div>
    
  );
}

export default TicketWatcher;
