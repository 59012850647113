import React, { useState, useEffect } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { fetchData } from '../api';
import './ParlayBuilder.css';

const propMapping = {
  "Points": "PTS",
  "Rebounds": "REB",
  "Assists": "AST",
  "Pts+Ast": "Pts+Ast",
  "Reb+Ast": "Ast+Reb",
  "Pts+Reb": "Pts+Reb",
  "Pts+Ast+Reb": "Pts+Ast+Reb",
};

const ParlayBuilder = ({
  parlayPlayers,
  removeFromParlay,
  clearParlay,
  keycloakId,  // Passed from your parent component
  token
}) => {
  const [betType, setBetType] = useState('parlay');

  useEffect(() => {
    if (parlayPlayers.length === 1) {
      setBetType('single');
    }
  }, [parlayPlayers]);

  const confirmBet = async () => {
    if (parlayPlayers.length === 0) {
      alert("Add players to your bet before confirming.");
      return;
    }

    // Validate that required fields are present.
    for (const player of parlayPlayers) {
      if (!player.game_date || !player.next_opponent || !player.over_under) {
        alert(
          `Missing required fields for player ${player.player_name || "unknown"}: ` +
          `${!player.game_date ? "game_date " : ""}` +
          `${!player.next_opponent ? "opponent " : ""}` +
          `${!player.over_under ? "over_under" : ""}`
        );
        return;
      }
    }

    // Build the bets payload from the selected players.
    const bets = parlayPlayers.map((player) => {
      const propToSave =
        player.prop ||
        propMapping[player.featureDisplay] ||
        player.featureDisplay ||
        "unknown_prop";
      return {
        player_bet_on: player.player_name,
        opponent: player.next_opponent,
        game_date: player.game_date,
        odds: parseFloat(player.odds),
        sportsbook: player.sportsbook || "",
        probability: parseFloat(player.feature_probability),
        team: player.team || "",
        threshold: parseFloat(player.threshold),
        over_under: player.over_under,
        prediction: player.feature_prediction || 23.5,
        prop: propToSave,
        bet_type: betType,
        timestamp: new Date().toISOString(),
      };
    });

    // Build the payload with the auth0_id (from keycloakId)
    const payload = {
      auth0_id: keycloakId,
      bets: bets,
    };

    try {
      // Call the create_ticket_and_bets endpoint.
      await fetchData('user-picks/create_ticket_and_bets/', token, 'POST', payload);
      alert(`Your ${betType === 'parlay' ? 'Parlay' : 'Single'} Bet has been saved.`);
      clearParlay();
    } catch (error) {
      console.error("Error saving bet:", error);
      alert("An error occurred while saving your bet. Please try again.");
    }
  };

  const multiplePlayers = parlayPlayers.length > 1;

  const handleRemovePlayer = (playerToRemove) => {
    removeFromParlay(playerToRemove.name);
  };

  return (
    <div className="parlay-builder-container">
      <div className="parlay-builder-header">
        <h3>Ticket Builder</h3>
        <div className="parlay-builder-top-buttons">
        
          {multiplePlayers ? (
            <select
              value={betType}
              onChange={(e) => setBetType(e.target.value)}
              className="bet-type-select"
            >
              <option value="parlay">Parlay</option>
              <option value="single">Single</option>
            </select>
          ) : (
            <div className="bet-type-forced">Single Bet</div>
          )}
          <button className="parlay-builder-refresh-btn" onClick={clearParlay}>
            🔄
          </button>
        </div>
      
      </div>

      <span className="parlay-builder-legs">
        {parlayPlayers.length} {parlayPlayers.length === 1 ? "Leg" : "Legs"}
      </span>

      {!parlayPlayers.length ? (
        <div className="parlay-builder-empty-message">Add players to the ticket</div>
      ) : (
        <table className="parlay-builder-table">
          <thead>
            <tr>
              <th>Player</th>
              <th>Prop</th>
              <th>Threshold</th>
              <th>Pick</th>
              <th>Remove</th>
            </tr>
          </thead>
          <tbody>
            {parlayPlayers.map((player, index) => {
              const shortProp =
                player.prop ||
                propMapping[player.featureDisplay] ||
                player.featureDisplay ||
                "unknown_prop";
              return (
                <tr key={index}>
                  <td>{player.player_name}</td>
                  <td>{shortProp}</td>
                  <td>{player.threshold}</td>
                  <td>{player.over_under}</td>
                  <td>
                    <button
                      onClick={() => handleRemovePlayer(player)}
                      className="parlay-builder-remove-btn"
                    >
                      <FaTrashAlt />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}

      <div className="parlay-builder-bottom">
        <button className="parlay-builder-confirm-btn" onClick={confirmBet}>
          Confirm {betType === 'parlay' ? "Parlay" : "Singles"} Ticket
        </button>
      </div>
    </div>
  );
};

export default ParlayBuilder;
