import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { matchups } from './MatchupInfo';  // still imported for the base matchups info
import { teamsDict } from './TeamInfo';
import { teamNameToAbbreviation } from './TeamDictionary';
import Matchup from './matchup_menu_components/Matchup';
import './MatchupMenu.css';
// If your fetchData function requires a token, import Keycloak
import { useKeycloak } from '../KeycloakProvider';
import { fetchData } from '../api'; // same fetchData approach

const MatchupMenu = () => {
  const [currentStartIndex, setCurrentStartIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(0);
  const [currentDate, setCurrentDate] = useState('');
  const [enhancedMatchups, setEnhancedMatchups] = useState([]);
  const navigate = useNavigate();

  // If an auth token is required for fetchData, get it from Keycloak
  const { token } = useKeycloak();

  useEffect(() => {
    // Format today's date in DD.MM
    const date = new Date();
    const formattedDate = `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(2, '0')}`;
    setCurrentDate(formattedDate);
  }, []);

  useEffect(() => {
    const updateItemsPerPage = () => {
      const container = document.querySelector('.matchup-menu');
      if (!container) return;
      const containerWidth = container.offsetWidth;
      const itemWidth = 180; // Adjusted width for each matchup
      const newItemsPerPage = Math.floor(containerWidth / itemWidth)-1;
      setItemsPerPage(newItemsPerPage || 1);
    };

    updateItemsPerPage();
    window.addEventListener('resize', updateItemsPerPage);
    return () => window.removeEventListener('resize', updateItemsPerPage);
  }, []);

  /**
   * Fetch picks/topPicks from your endpoint:
   * /api/player-feature-predictions/get_top_picks_count?homeTeam=HOME&awayTeam=AWAY
   */
  async function fetchPicksForMatchup(homeTeam, awayTeam) {
    let picks = 0;
    let topPicks = 0;
    try {
      const endpoint = `player-feature-predictions/get_top_picks_count/?homeTeam=${encodeURIComponent(teamNameToAbbreviation[homeTeam])}&awayTeam=${encodeURIComponent(teamNameToAbbreviation[awayTeam])}`;
      const response = await fetchData(endpoint, token); // or direct fetch if no token needed
      // The endpoint returns { "count": total_count }
      topPicks = response.count || 0;
      // If you want "picks" to be the same as topPicks, do picks = topPicks.
      // Or a separate random picks logic:
      picks = 30 + Math.floor(Math.random() * 61); // random 30–90
    } catch (error) {
      console.error(`Failed to fetch picks for ${homeTeam} vs ${awayTeam}:`, error);
    }
    return { picks, topPicks };
  }

  // On mount, transform the static matchups array into one that has real picks/topPicks
  useEffect(() => {
    let isCancelled = false;
    
    (async () => {
      const updated = [];
      for (const m of matchups) {
        const [awayTeam, homeTeam] = m.matchup.split(' @ ');
        const { picks, topPicks } = await fetchPicksForMatchup(homeTeam, awayTeam);
        updated.push({
          ...m,
          picks,
          topPicks
        });
      }
      if (!isCancelled) setEnhancedMatchups(updated);
    })();

    return () => {
      isCancelled = true;
    };
  }, [token]); // re-run if token changes

  const handleNext = () => {
    if (currentStartIndex + itemsPerPage < enhancedMatchups.length) {
      setCurrentStartIndex(currentStartIndex + itemsPerPage);
    }
  };

  const handlePrevious = () => {
    if (currentStartIndex - itemsPerPage >= 0) {
      setCurrentStartIndex(currentStartIndex - itemsPerPage);
    }
  };

  const handleMatchupClick = (matchup) => {
    const [awayTeam, homeTeam] = matchup.matchup.split(' @ ');
    const awayTeamAbbr = teamNameToAbbreviation[awayTeam] || awayTeam;
    const homeTeamAbbr = teamNameToAbbreviation[homeTeam] || homeTeam;

    navigate(`/MatchupDashboard`, {
      state: {
        matchup: {
          awayTeamAbbr,
          homeTeamAbbr,
          time: matchup.time,
          picks: matchup.picks,
          topPicks: matchup.topPicks,
        },
      },
    });
  };

  return (
    <div className="matchup-menu-wrapper">
      <div className="matchup-menu-date-box">
        <span className="matchup-menu-date">{currentDate}</span>
      </div>
      <div className="matchup-menu-container">
        <div className="matchup-menu">
          {enhancedMatchups.slice(currentStartIndex, currentStartIndex + itemsPerPage).map((matchup, index) => {
            const [awayTeam, homeTeam] = matchup.matchup.split(' @ ');
            const awayTeamAbbr = teamNameToAbbreviation[awayTeam] || awayTeam;
            const homeTeamAbbr = teamNameToAbbreviation[homeTeam] || homeTeam;

            return (
              <div
                key={index}
                className="matchup-menu-link"
                onClick={() => handleMatchupClick(matchup)}
              >
                <Matchup
                  team1Logo={teamsDict[homeTeam]?.logo || '/default-logo.png'}
                  team2Logo={teamsDict[awayTeam]?.logo || '/default-logo.png'}
                  team1Name={homeTeamAbbr}
                  team2Name={awayTeamAbbr}
                  time={matchup.time}
                  picks={matchup.picks}
                  topPicks={matchup.topPicks}
                  isHomeGame={true}
                />
              </div>
            );
          })}
        </div>
        <div className="arrows-container">
          <button
            className="arrow-button up"
            onClick={handleNext}
            disabled={currentStartIndex + itemsPerPage >= enhancedMatchups.length}
          ></button>
          <button
            className="arrow-button down"
            onClick={handlePrevious}
            disabled={currentStartIndex === 0}
          ></button>
        </div>
      </div>
    </div>
  );
};

export default MatchupMenu;
